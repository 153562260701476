







































































import Vue from "vue";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { truncate } from "@/apps/core/modules/utils/text";
import { FilterState, FilterStates } from "../models/filterState";

export default defineComponent({
  name: "ListFilter",
  props: {
    filterStates: { type: Object, required: true },
    searchActiveState: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const cari: Ref<any> = ref(null);
    const isSearchActive = ref(false);
    // harus gunakan clone agar state terpisah
    const aFilterStates = reactive(props.filterStates.clone() as FilterStates); // make reactive

    const onFilterChanged = (filterState: FilterState, value: string) => {
      filterState.value = value;
      emit("input", aFilterStates);
    };

    const isFilterActive = computed(() => {
      const hasSearch = aFilterStates.hasSearch && !!aFilterStates.q;
      const hasFilters = aFilterStates.list.some((state) => !!state.value);
      return hasSearch || hasFilters;
    });

    const getButtonClass = (value: string) => {
      return value ? "has-text-info-dark	has-background-info-light" : "";
    };

    const setSearchActive = async (value: boolean) => {
      isSearchActive.value = value;
      if (value) {
        await Vue.nextTick();
        const divEl: HTMLElement = cari.value.$el;
        const el = divEl.getElementsByTagName("input")[0];
        el.focus();
        el.click();
      }
    };

    const onSearch = () => {
      if (aFilterStates.q.length == 0 || aFilterStates.q.length >= 3) {
        setSearchActive(false);
        emit("input", aFilterStates);
      }
    };

    const resetStates = () => {
      aFilterStates.reset();
      emit("input", aFilterStates);
    };

    // watch request perubahan init filter
    watch(
      () => props.filterStates as FilterStates,
      (value) => {
        aFilterStates.reset();
        // harus gunakan clone, agar state terpisah
        Object.assign(aFilterStates, value.clone());
      },
      { deep: true }
    );

    return {
      // Data
      aFilterStates,
      cari,
      isSearchActive,

      // Computed
      isFilterActive,

      // Method
      getButtonClass,
      onFilterChanged,
      onSearch,
      resetStates,
      setSearchActive,
      truncate,
    };
  },
});
